// Style page d'accueil

	.bloc-2 {
		.container {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.pastille-asm-svg {
			position: absolute;
			left: 2rem;
			bottom: 2rem;
			width: 15rem;
			height: 15rem;
			z-index: 1;

			@media (max-width: $medium) {
				width: 9rem;
				height: 9rem;
			}

			@media (max-width: $tiny) {
				width: 7rem;
				height: 7rem;
				left: 1rem;
				bottom: 1.5rem;
			}
		}
	}

	.bloc-3 {
		@media (max-width: $x-large) {
			display: none;
		}

		.container {
			@media (max-width: $x-large) {
				height: initial;
			}
		}

		.signe {
			top: 50%;
			left: 0;
			max-height: 100%;
			padding: 1.5rem;
			transform: translateY(-50%);

			@media (max-width: $x-large) {
				display: none;
			}
		}

		.links-wrapper {
			@media (min-width: $x-large + 1) {
				position: absolute;
				top: 2rem;
				left: 16rem;
				right: 2rem;
				bottom: 2rem;
			}

			@media (max-width: $x-large) {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				position: relative;
				max-width: 90rem;
			    padding: 5rem 2rem;
			    margin: 0 auto;
			    z-index: 2;
			}

			@media (max-width: $small) {
				padding: 2rem .5rem;
			}
		}

		a {
			padding: 1rem;
			font-size: 2.8rem;
			text-decoration: none;
			font-weight: 400;
			color: $jaune-asm;

			span {
				color: $jaune-asm;
				transition: color .3s ease-in-out;
			}

			&:hover span {
				color: $white;
			}

			@media (min-width: $x-large + 1) {
				position: absolute;
				display: inline-block;

				&.raison {
					top: 15%;
					left: 25%;
				}

				&.philosophie {
					top: 10%;
					right: 20%;
				}

				&.transformation {
					top: 35%;
					left: 5%;
				}

				&.contribution {
					top: 42%;
					right: 5%;
				}

				&.modalites {
					bottom: 15%;
					left: 15%;
				}

				&.equipe {
					bottom: 40%;
					right: 45%;
				}

				&.actualites {
					bottom: 10%;
					right: 25%;
				}
			}

			@media (max-width: $x-large) {
				width: 50%;
			}

			@media (max-width: $small) {
				width: 100%;
				font-size: 2rem;
			}
		}

		img:not(.signe) {
			@media (max-width: $x-large) {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: auto;
				z-index: 1;
			}

			@media (max-width: $medium) {
				display: none;
			}
		}
	}

	.bloc-4 {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 41rem;

		@media (max-width: $small-plus) {
			height: initial;
		}
	
		.container-tiny {
			margin: 0;
			padding: 4rem;
			text-align: center;
			font-size: 2.4rem;
			i{
				font-weight: 300;
			}

			@media (max-width: $small) {
				padding: 3rem 1.5rem;
				font-size: 2rem;
			}

			p:not(:last-of-type) {
				margin-bottom: 3rem;
				font-weight:300;
			}

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.bloc-5 {
			height:40rem;
		.container {
			padding:2rem;

			.grid-2{
				align-items:center;

				@media (max-width: $medium) {
					text-align:center;
					.citation{
						text-align:left;
					}
				}
				img {
					height:auto;
					margin:0 auto;
					
					@media (max-width: $medium) {
				        		width:50%;
				        		height:auto;

								margin:0 auto;
				        	}
			}

		}
			
		}
		.citation{
			font-style: italic;
			color: #ffffff;
			font-size: 2.2rem;
			// font-weight:300;
			// align-items:center;

			span {
				// font-weight:bold;
				font-style:normal;
				display:block;
				font-size: 1.7rem;
				padding-top:1rem;

				@media (max-width: $medium){
			    font-size: 2rem;
				}
			}

			@include respond-to("medium-up") {
			    font-size: 4rem;
			}
			@include respond-to("small-up") {
			    font-size: 3rem;
			}
		}
	
	}

	.bloc-6 {
		.texte-wrapper {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			padding: 2rem;
			text-align: center;
			font-size: 4.4rem;
			line-height: 7rem;
			color: $jaune-asm;
			transform: translateY(-50%);

			p {
				margin: 0;

				strong {
					margin-left: 5rem;
				}
			}
		}
	}

	.bloc-7 {
		.container {
			padding: 4rem;

			@media (min-width: $small-plus + 1) {
				min-height: 41rem;
			}

			@media (max-width: $small) {
				padding: 3rem 1.5rem;
			}
		}

		p {
			position: relative;
		 	max-width: 87rem;
		 	margin: 0 auto;
		    font-weight: 400;
			font-size: 8rem;
		    line-height: 7rem;
		    letter-spacing: -.45rem;
		    z-index: 1;

		    @media (max-width: $small) {
		    	font-size: 5rem;
		    	line-height: initial;
		    }

		    @media (max-width: $tiny) {
		    	font-size: 3.8rem;
		    	letter-spacing: -.2rem;
		    }
 
			strong {
				font-weight: 400;
				color: $bleu-asm;
			}
		}

		img {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			max-width: 100%;
			transform: translateY(-50%);
		}
	}

	.bloc-9 {
		.signe {
			right: 20rem;
			bottom: 3rem;
		}
	}

	.bloc-10 {
		@media (max-width: $large) {
			.container-wrapper {
				&:first-of-type .demi-container {
					margin: 0;
				}

				&:last-of-type {
					background-color: $jaune-asm;

					.demi-container {
						margin: 0 0 0 auto;
					}
				}
			}
		}
	}

	.bloc-12 {
		z-index: 1;

		.container-wrapper:first-of-type {
			position: relative;
			z-index: 1;
		}

		.signe-1 {
			bottom: 4.5rem;
    		right: 15%;

    		@media (max-width: $small) {
    			width: 25vw;
    		}
		}

		.signe-2 {
			left: 2rem;
    		bottom: -8rem;
		}
	}

	.main-accueil .bloc-13, .main-home .bloc-13 {
		@media (max-width: $large) {
			.container-wrapper:first-of-type {
				order: 1;
			}
		}
	}

	.bloc-13 {
		.signe {
		top: 50%;
	    left: 30%;
	    transform: translateY(-50%);
	}

		.demi-texte ul li:before{
			color:#EEC14F;
		}
	}

	


	.bloc-14 {
		.container {
			padding: 4rem;
			text-align: center;

			@media (min-width: $small-plus + 1) {
				min-height: 41rem;
			}

			@media (max-width: $small) {
				padding: 3rem 1.5rem;
			}
		}

		p {
			position: relative;
		 	margin: 0;
		    font-weight: 400;
			font-size: 8rem;
		    line-height: 7rem;
		    letter-spacing: -.45rem;
		    z-index: 1;
		    color:$blanc;
		    text-align:left;

		    @media (max-width: $small) {
		    	font-size: 5rem;
		    	letter-spacing: -.2rem;
		    	line-height: initial;
		    }

		    // @media (max-width: $tiny) {
		    // 	font-size: 3.8rem;
		    // 	letter-spacing: -.2rem;
		    // }
 
			strong {
				font-weight: 400;
				color: $jaune-asm;
			}
		}
	}
	.bloc-photo-partage{
		// position:absolute;
		a{
			// position:absolute;
			color:#fff;

		}

	}
