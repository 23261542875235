// Style page Contact

	.contact {
		min-height: calc(100vh - 4.1rem);

		@media (max-width: $x-large) {
			flex-direction: column;
			min-height: initial;
		}

		.map {
			width: 50%;
			z-index: 0;

			@media (max-width: $x-large) {
				width: 100%;
				height: 35rem;
			}

			@media (max-width: $medium) {
				height: 25rem;
			}

			@media (max-width: $tiny) {
				height: 18rem;
			}
		}

		.container-wrapper {
			color: $bleu-asm;
			background-color: $jaune-asm;
		}

		.demi-container {
			height: 100%;
			padding: 0 2rem;
		}

		h1 {
			margin-bottom: 2rem;
			font-size: 5rem;
			color: $blanc;

			@media (max-width: $medium) {
				font-size: 4rem;
			}

			@media (max-width: $tiny) {
				font-size: 3rem;
			}
		}

		.info-contact {
			a {
				position: relative;
				font-weight: 400;
				text-decoration: none;
				transition: all .3s ease-in-out;

				&:after {
					position: absolute;
					bottom: -.2rem;
					left: 0;
					right: 0;
					height: .1rem;
					content: '';
					background-color: $bleu-asm;
				}
			}

			svg {
				width: 2rem;
				height: 2rem;
				vertical-align: sub;

				&:last-of-type {
					margin-right: .5rem;
				}
			}

			&:nth-of-type(2) {
				margin-bottom: 1rem;
			}

			&:last-of-type {
				margin-bottom: 3rem;
			}
		}

		input, textarea {
			width: 100%;
			padding: 1.5rem;
			border: .2rem solid $blanc;
			outline: none;
			background-color: $jaune-asm;
			transition: background-color .3s ease-in-out;

			@media (max-width: $x-large) {
				padding: 1rem;
			}

			&:focus {
				background-color: $blanc;
			}
		}

		label.error {
			margin: .5rem 0 0;
			font-weight: 400;
			color: $error;
		}

		.form-message {
			width: 100%;
			margin-bottom: 1.5rem;
			padding: 1.5rem;
			color: $blanc;

			&.form-notice {
				background-color: $success;
			}

			&.form-erreur {
				background-color: $error;
			}
		}

		.g-recaptcha {
			margin: 2rem 0;
		}

		.form-submit {
			padding: 1.5rem 2rem;
			outline: none;
			transition: background-color .3s ease-in-out;

			&:hover {
				background-color: $bleu-asm;
			}
		}

		.back {
			display: table;
			margin-top: 4rem;
			padding: 0 .5rem .2rem;
			text-decoration: none;
			border: .1rem solid $bleu-asm;
			color: $bleu-asm;
			transition: all .3s ease-in-out;

			&:hover {
				color: $blanc;
				background-color: $bleu-asm;
			}
		}
	}