// style de la page qui sommes nous




// header de page
.main-a-propos-de-nous .signe{
	position:relative;
}
.main-a-propos-de-nous .container, .main-about-us .container{
    max-width: 120rem;
    height:auto;
}


// content
p.legende-grah{
	text-align:center;
	font-weight:300;
	padding-top:2rem;
	font-style:italic;
}