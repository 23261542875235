// Style pages ASM Conseils
	
	.main-votre-besoin, .main-your-needs {
		display: flex;
		flex-direction: column;
	}

	.bloc-7-content {
		// flex-grow: 1;
		position: relative;
		background: {
			position: center bottom;
			repeat: no-repeat;
			size: cover;
		}
		 @media (max-width: $small) {
			background-image:none!important;
			}

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			background: linear-gradient(to bottom, $white, rgba($white, .5));
			// z-index: 1;
		}

		.container-tiny, .container {
			position: relative;
			z-index: 2;
		}

		.container {
			margin-top: 8rem;

			@media (max-width: $medium) {
				margin-top: 4rem;
			}
		}
	}

	.bloc-9-content {
		img {
		padding: 8rem 0;

		@media (max-width: $medium) {
			padding: 4rem 0;
			}
		}
		p{
			padding-left:2rem;
		}
		h2{
			font-size:100%;
		}

	}

	.bloc-10-content {
		position: relative;
		background: {
			position: center bottom;
			repeat: no-repeat;
			size: cover;
		}

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			background: linear-gradient(to bottom, $bleu-asm, rgba($bleu-asm, .7));
			z-index: 1;
		}

		.container-tiny {
			position: relative;
			padding: 2rem;
			color: $white;
			z-index: 2;
		}
	}

	.bloc-12-content {
		.flex-container {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			@media (max-width: $large) {
				flex-direction: column;
				justify-content: center;
			}

			> div {
				width: calc(50% - 1.5rem);

				@media (max-width: $large) {
					width: 100%;

					&.img-wrapper {
						order: 1;
						margin-top: 8rem;

						@media (max-width: $medium) {
							margin-top: 4rem;
						}

						img {
							display: block;
							width: 60rem;
							margin: 0 auto;
						}
					}
				}
			}
		}
	}

	.main-l-equipe .bloc-13 .container-wrapper:last-of-type, .main-the-team .bloc-13 .container-wrapper:last-of-type {
		background-position: center bottom;
	}

	.bloc-13-content {
		
		.container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: start;
			height: inherit;
			padding-top: 5rem;
			padding-bottom: 5rem;

			@media (max-width: $medium) {
        		padding-top: 3rem;
        		padding-bottom: 3rem;
        	}

			.survol {
				width: 100%;
				font-size: 1.4rem;
				text-align:left;
			}

			.membre {
				position: relative;
				@media (max-width: $small) {
        		width:100%;
			}
				

				&.membre-hover .membre-texte {
					transform: scale(1);
					opacity: 1;
				}

				.membre-texte {
					flex-direction: column;
					justify-content: center;
					align-items: center;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					padding: 2rem;
					text-align: center;
					transform: scale(1.05);
					opacity: 0;
					transition: all .3s ease-in-out;
					z-index: 1;
					color: $white;
					background-color: $bleu-asm-shade;
					display:flex;
					@media (max-width: $medium) {
				        		height:auto;
				        		padding-top:55%;

				        	}

					h2 {
						margin-bottom: 2rem;
						@media (max-width: $medium) {
				        		margin-bottom:0;
				        	}
					}

					p {
						margin: 0;
						@media (max-width: $medium) {
				        		line-height:1;
				        		font-size:1.3rem;
				        	}
					}
					p a{
						display:block;
					}
					p a img{
						width:4rem;
						height:auto;
						margin-top:1rem;
						padding:0;
						@media (max-width: $medium) {
				        		width:3rem;
				        	}
					}
					p a:hover img{
						filter: blur(2px);
					}
				}

				img {
					width: 100%;
					
				}
			}
		}
		.intro-equipe {
						text-align: right;
						padding-top:0;
						padding-bottom:0;
						justify-content: flex-end;
						
						h2{
							font-style: italic;

							@media (max-width: $medium) {
				        		font-size:2rem;
				        	}
						}

					}
		.container-tiny {
			min-height: 100%;
		}
	}
	// ------------------------------------
// ------------------------------------
// ------------------------------------

// -- PAGE EQUIPE ---surcharge E

// ------------------------------------
// ------------------------------------
// ------------------------------------


.main-l-equipe{
	.bloc-5{
		.container .grid-2 img{
			width:100%;
		}
	}
}
.bloc-13-content {
		
		.container {
			.membre{
				@media (max-width: $medium) {
					.membre-texte {
						opacity:1;
						-webkit-transform: scale(0);
						transform: scale(0); 
					}
					
				}
			}
			
			.remplissage{
				display: flex;
				text-align:center;
				align-items: center;
				vertical-align: middle;
				width:30%;
				padding:2rem;
				@media (max-width: $medium) {
					width:50%;

				}

				@media (max-width: $small) {
					width:100%;

				}
				
				cite{
					font-size:3rem;
					text-align:left;

					@media (max-width: $small) {
				        		font-size:4rem;

				        		// line-height:1rem;
				        	}
				}
					
				
			}
		}
	}

// ------------------------------------
// ------------------------------------
// ------------------------------------

// -- PAGES VOTRE BESOINS ---surcharge E

// ------------------------------------
// ------------------------------------
// ------------------------------------

.main-votre-besoin, .main-your-needs {

		 .container{
			margin-top:0;
			}
			.container-tiny{
				text-align: center;
				img{
					width:50%;
					padding-top:2.5rem;
					 @media (max-width: $small) {
									    width:100%;
									    }

				}
			}
		 .list-besoins-content{
			padding:0 2rem;
			max-width: 80rem;
			margin:0 auto;
			
			.besoins {

				h2 {
					position:relative;
					padding-right:1.5rem;
						
						&:nth-child(odd){
							&::after{
								content:' ...';
								position:absolute;
								top:0rem;
								right:-1rem;
									 @media (max-width: $small) {
								    	display:none;
								    }
								}
							&:nth-last-child{
								content:'';
								}
							}
					}
				p{
				padding-left: 1.5rem;
				padding-top:1.3rem;
				 @media (max-width: $small) {
				 	padding-left:0;
								    	
						}
					}

				}
			
			 }
		
			


}
// ------------------------------------
// ------------------------------------
// ------------------------------------

// -- PAGE COOPERATION ET PHILO --

// ------------------------------------
// ------------------------------------
// ------------------------------------


.main-cooperation, .main-our-cooperation-modes {
	.bloc-10-content{

		.container-tiny{
 		p{
 		padding-left:2rem;
 		}

 	}

	}
 .bloc-10-philo{

 	.container-wrapper{
 		background-color:$gray;

 	}	
 	
 	
 }
}

// ------------------------------------
// ------------------------------------
// ------------------------------------

// -- ACTUS // SYNTHESE --

// ------------------------------------
// ------------------------------------
// ------------------------------------

.main-actualites, .main-for-sharing{
		.bloc-7 p{
				position: relative;
			    margin: 0 auto;
			    font-weight: 400;
			    font-size: 8rem;
			    line-height: 7rem;
			    letter-spacing: -.45rem;
			    z-index: 1;

	}
	.news-single-content{
		padding-bottom:2rem;
	}
	.txt-wrapper{
		padding-left:2rem;
		 @media (max-width: $small) {
				padding-left:0;
								    	
		}
	}
}




