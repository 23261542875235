
	// Navigation

		header {
			position: fixed;
			top: 0;
			right: 0;
			z-index: 4;

			&.is-mobile-nav-open {
				.links-wrapper {
					pointer-events: auto;
					opacity: 1;
				}

				.hamburger:after {
					width: 300vw;
					height: 300vw;

					@media (max-width: $large) {
				    	width: 400vw;
						height: 400vw;
				    }
				}
			}
			
			nav {
				justify-content: flex-end;
				align-items: center;
			}

			.link-accueil {
				position: fixed;
				left: 1rem;
				top: 1rem;

				&:hover svg path, &:hover svg circle {
					&.fill-bleu {
						fill: $jaune-asm;
					}

					&.fill-jaune {
						fill: $bleu-asm;
					}
				}
			}

			.logo-asm-small {
				width: 12rem;	
				height: 4.7rem;

				path, circle {
					transition: fill .2s ease-in-out;
				}
			}

			.links-wrapper {
				flex-direction: column;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 10.8rem;
				right: 0;
				bottom: 0;
				width: 100vw;
				height: calc(100vh - 5.4rem);
				margin-top: -5.4rem;
				pointer-events: none;
				opacity: 0;
				transition: all .3s ease-in-out .3s;
				z-index: 2;

				@media (max-width: $small) {
					top: 8.8rem;
					height: calc(100vh - 4.4rem);
					margin-top: -4.4rem;
				}
			}

			.nav-link {
				padding: 1rem;
				font-size: 2.4rem;
				font-weight: 400;
				text-decoration: none;
				transition: color .3s ease-in-out;

				@media (max-width: $small) {
					font-size: 1.8rem;
				}

				&:hover, &.is-active {
					color: $blanc;

					@media (max-width: $large) {
						color: $jaune-asm;
					}
				}
			}

			.hamburger {
				justify-content: center;
				align-items: center;
				position: relative;
				margin: 0 0 0 auto;
				outline: none;
				font-size: 1.7rem;
				background-color: $jaune-asm;

				@media (max-width: $large) {
					background-color: $white;
				}

				&:after {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 0;
					height: 0;
					content: '';
					border-radius: 50%;
					transform: translateX(-50%) translateY(-50%);
					background-color: $jaune-asm;
					transition: width .5s $ease-bounce, height .5s $ease-bounce;

					@media (max-width: $large) {
						background-color: $white;
					}
				}

				&:hover .hamburger-label {
					color: $white;

					@media (max-width: $large) {
						color: $jaune-asm;
					}
				}

				.hamburger-label {
					padding-right: 1rem;
					text-transform: uppercase;
					font-weight: 400;
					transition: color .2s ease-in-out;
					z-index: 2;

					@media (max-width: $small) {
						display: none;
					}
				}

				.hamburger-box {
					position: relative;
					z-index: 1;
				}

				.langues-wrapper {
					margin-left: 1rem;
					z-index: 2;
					font-weight: 400;

					@media (max-width: $small) {
						order: -1;
						margin: 0 1rem 0 0;
					}

					a {
						text-decoration: none;
						color: $bleu-asm;
						transition: color .2s ease-in-out;

						&:hover, &.is-active {
							color: $white;

							@media (max-width: $large) {
								color: $jaune-asm;
							}
						}
					}
				}
			}
		}