		
	// Styles Communs

		html {
			display: flex;
			flex-direction: column;
		}

		body {
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			font: 300 2rem $font-stack-common;
			color: $bleu-asm;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			&.is-fixed {
				overflow: hidden;
			}
		}

		main {
			flex: 1 1 auto;
			overflow: hidden;
		}

		a {
			outline: none;
			text-decoration: none;
		}

		ul {
			margin-bottom: 0;

			li {
				margin-bottom: 2rem;

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		::selection {
			color: $black;
		  	background: $jaune-asm;
		}
		header{
			width:100%;
			transition: all 0.2s ease-in-out;
    		background-color:transparent;  
		}
		header.active{
			background-color:$blanc;
		}

		section {
			position: relative;

			&.flex-container {
				flex-wrap: nowrap;

				@media (max-width: $large) {
					flex-direction: column;
				}
			}

			.link-section-page {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
			}
		}

		.section-image {
			height: 41rem;
			background: {
				position: center center;
				repeat: no-repeat;
				size: cover;
			}

			@media (max-width: $medium) {
				height: 30rem;
			}

			@media (max-width: $tiny) {
				height: 25rem;
			}
		}

		.section-content {
			// min-height: calc(100vh - 45.5rem);
			padding: 8rem 0;

			@media (max-width: $medium) {
				padding: 4rem 0;
			}

			@media (max-width: $small) {
				font-size: 1.7rem;
			}

			.container {
				height: initial;
			}
		}

		/// Style SVG

		.fill-bleu {
			fill: $bleu-asm;
		}

		.fill-jaune {
			fill: $jaune-asm;
		}

		.fill-blanc {
			fill: $blanc;
		}

		/// Style couleurs
		.bg-jaune, .bg-bleu {
			color: $white;
		}

		.bg-jaune {
			background-color: $jaune-asm;
		}

		.bg-bleu {
			background-color: $bleu-asm;
		}

		.bg-gris {
			background-color: $gris-clair;
		}

		.bg-cover {
			width: 100%;
			height: 100%;
			background: {
				position: center center;
				repeat: no-repeat;
				size: cover;
			}
		}

		///// Containers custo

		.container-wrapper {
			width: 50%;

			@media (max-width: $x-large) {
		    	width: 100%;
		    }
		}

		.container, .demi-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: relative;
			height: 100%;

			@media (min-width: $large + 1) {
				height: 41rem;
			}
		}

		.container, .container-tiny {
			margin: 0 auto; 
			padding: 0 2rem;
		}

        .container {
        	max-width: 120rem;
        }

        .container-tiny {
        	max-width: 80rem;
        }

        .demi-container {
        	display: flex;
			flex-direction: column;
			justify-content: center;
            max-width: 60rem;

            &.left {
            	margin: 0 0 0 auto;
            	text-align: right;

            	p, ul {
            		margin-left: auto;
            	}
            }

            &.right {
            	margin: 0 auto 0 0;
            }
        }
		
		.demi-texte {
			font-size: 2.5rem;

			@media (max-width: $small) {
				font-size: 2rem;
			}

			.demi-container {
				padding: 4rem;

				@media (min-width: $small-plus + 1) {
					min-height: 41rem;
				}

				@media (max-width: $small) {
					padding: 3rem 1.5rem;
				}
			}

			p, ul {
				position: relative;
				max-width: 42rem;
				z-index: 1;

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			ul {
				margin-top: 2rem;
				padding: 0;

				li {
					position: relative;
					display: inline-block;
					margin-bottom: 1rem;

					&:last-of-type {
						margin-bottom: 0;
					}

					&:before {
						margin-right: .7rem;
						content: '●';
						font-size: 1.7rem;
					}
				}
			}

			em {
				font-size: 2.8rem;

				@media (max-width: $small) {
					font-size: 2.2rem;
				}
			}

			strong {
				font-weight: 300;
				font-size: 1.7rem;

				@media (max-width: $small) {
					font-size: 1.5rem;
				}
			}
		}

        .p-section {
        	padding-top: 8rem;
        	padding-bottom: 8rem;

        	@media (max-width: $large) {
        		padding-top: 5rem;
        		padding-bottom: 5rem;
        	}

        	@media (max-width: $tiny) {
        		padding-top: 3rem;
        		padding-bottom: 3rem;
        	}
        }

        .signe {
        	position: absolute;
        }

        // Style slider

        .slick-list, .slick-track, .slick-slide, .slick-slide div, .slider {
			height: 100%;
		}

		// Mentions légales, plan du site, 404

		.mentions-legales h1, .plan-du-site h1 {
			margin-bottom: 3rem;
			font-size: 4rem;
			color: $jaune-asm;
		}

		.mentions-legales a {
			padding: 0 .5rem;
		}

		.plan-du-site {
        	.plan-item {
				margin-bottom: 1.5rem;
			}

			.item-sub-wrapper {
				position: relative;

				&:before {
					position: absolute;
					top: 0;
					width: 1.7rem;
	    			height: 1.7rem;
					content: '';
					border-left: .1rem solid rgba($bleu-asm, .3);
					border-bottom: .1rem solid rgba($bleu-asm, .3);
				}
			}

			.item-sub-wrapper:before {
	    		left: 2.5rem;
			}

			.plan-item-titre, .item-sub {
				position: relative;
				display: block;
				padding: .5rem 1rem;

				&:after {
					content: none;
				}
			}

			.plan-item-titre {
				font-weight: 400;
				font-size: 1.8rem;
				letter-spacing: .1rem;
				text-transform: uppercase;
			}

			.item-sub {
				margin-left: 4rem;
				font-size: 1.5rem;
				text-transform: uppercase;
				letter-spacing: .1rem;
			}
        }

		.error-404 {
			align-items: center;
			min-height: calc(100vh - 4.5rem);
			background: linear-gradient(to bottom, $jaune-asm 10rem, $white);

			h1 {
				margin-left: -1.2rem;
				font-size: 15rem;
			}

			a {
				font-weight: 400;
			}
		}
