
	// Pied de page

	footer {
		padding: 1rem;

		.link-wrapper-footer {
			justify-content: space-between;
			align-items: center;

			@media (max-width: $large) {
				& > div {
					width: 100%;
				}
			}

			p, a {
				display: inline-block;
				font-size: 1.5rem;
				font-weight: 400;

				@media (max-width: $tiny) {
					font-size: 1.3rem;
				}
			}

			p {
				margin: 0 1.5rem 0 0;
			}

			div {				
				&:first-of-type {
					@media (max-width: $tiny) {
						text-align: center;
					}

					a {
						margin-right: 1.5rem;
					}
				}

				&:last-of-type {
					@media (max-width: $large) {
						margin-top: .5rem;
						text-align: right;
					}

					@media (max-width: $tiny) {
						text-align: center;
					}

					a {
						margin-left: 1.5rem;
					}
				}
			}

			a {
				padding: 0 .4rem .2rem;
				text-decoration: none;
				transition: color .3s ease-in-out;

				span {
					color: $bleu-asm;
					transition: color .3s ease-in-out;
				}

				&.link-linkedin {
					padding: 0;

					img {
						vertical-align: sub;
					}
				}

				&.border {
					border: .1rem solid $jaune-asm;
					transition: background-color .3s ease-in-out;

					&.link-contact {
						background-color: $jaune-asm;
					}

					&:hover {
						background-color: $jaune-asm;

						&.link-contact {
							background-color: transparent;
						}

						span {
							color: $blanc;
						}
					}
				}

				&:hover:not(.border) {
					color: $jaune-asm;
				}
			}
		}
	}