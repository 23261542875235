
	// Styles de formulaire
		
		// Reset

			input[type=number] {
				-moz-appearance: textfield;
			}

			input[type=number]::-webkit-inner-spin-button, 
			input[type=number]::-webkit-outer-spin-button { 
				-webkit-appearance: none; 
				margin: 0; 
			}

			input:-webkit-autofill { // Background Yellow on Chrome
			    -webkit-box-shadow: 0 0 0 3rem white inset;
			}

			::-ms-clear {
		        display: none;
		  	}

		  	::-ms-reveal {
		        display: none;
		  	}

		  	input[type=text], textarea {   
		    	-webkit-appearance: none;
		   	 	-moz-appearance: none;
		    	appearance: none;
		    }

		// Placeholder

			::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: $blanc;
			}

			::-moz-placeholder { /* Firefox 19+ */
				color: $blanc;
			}

			:-ms-input-placeholder { /* IE 10+ */
				color: $blanc;
			}

			:-moz-placeholder { /* Firefox 18- */
				color: $blanc;
			}

