
	// Fonts (Polices de caractère)

	//// Slick

		@font-face {
			font-family: 'slick';
		    src: url('../fonts/slick/slick.woff2') format('woff2'),
		    	 url('../fonts/slick/slick.woff') format('woff'),
		         url('../fonts/slick/slick.ttf') format('truetype'),
				 url('../fonts/slick/slick.svg#Slick') format('svg');
		}

	// Branding

			@font-face {
			    font-family: 'Branding';
			    src: url('../fonts/Branding/Branding-Medium.woff2') format('woff2'),
			         url('../fonts/Branding/Branding-Medium.woff') format('woff'),
			         url('../fonts/Branding/Branding-Medium.ttf') format('truetype'),
					 url('../fonts/Branding/Branding-Medium.svg#BrandingMedium') format('svg');
			    font-weight: 300;
			    font-style: normal;
			}
			@font-face {
			    font-family: 'Branding';
			    src: url('../fonts/Branding/Branding-MediumItalic.woff2') format('woff2'),
			         url('../fonts/Branding/Branding-MediumItalic.woff') format('woff'),
			         url('../fonts/Branding/Branding-MediumItalic.ttf') format('truetype'),
			         url('../fonts/Branding/Branding-MediumItalic.svg#brandingmediumitalic') format('svg');
			    font-weight: 300;
			    font-style: italic;
			}

			@font-face {
			    font-family: 'Branding';
			    src: url('../fonts/Branding/Branding-Semibold.woff2') format('woff2'),
			         url('../fonts/Branding/Branding-Semibold.woff') format('woff'),
			         url('../fonts/Branding/Branding-Semibold.ttf') format('truetype'),
					 url('../fonts/Branding/Branding-Semibold.svg#BrandingSemiBold') format('svg');
			    font-weight: 400;
			    font-style: normal;
			}

			@font-face {
			    font-family: 'Branding';
			    src: url('../fonts/Branding/Branding-SemiboldItalic.woff2') format('woff2'),
			         url('../fonts/Branding/Branding-SemiboldItalic.woff') format('woff'),
			         url('../fonts/Branding/Branding-SemiboldItalic.ttf') format('truetype'),
					 url('../fonts/Branding/Branding-SemiboldItalic.svg#BrandingSemiBoldItalic') format('svg');
			    font-weight: 400;
			    font-style: italic;
			}

			@font-face {
			    font-family: 'Branding';
			    src: url('../fonts/Branding/Branding-Bold.woff2') format('woff2'),
			         url('../fonts/Branding/Branding-Bold.woff') format('woff'),
			         url('../fonts/Branding/Branding-Bold.ttf') format('truetype'),
					 url('../fonts/Branding/Branding-Bold.svg#BrandingBold') format('svg');
			    font-weight: 700;
			    font-style: normal;
			}

			@font-face {
			    font-family: 'Branding';
			    src: url('../fonts/Branding/Branding-BoldItalic.woff2') format('woff2'),
			         url('../fonts/Branding/Branding-BoldItalic.woff') format('woff'),
			         url('../fonts/Branding/Branding-BoldItalic.ttf') format('truetype'),
					 url('../fonts/Branding/Branding-BoldItalic.svg#BrandingBoldItalic') format('svg');
			    font-weight: 700;
			    font-style: italic;
			}
		